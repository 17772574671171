import { useEffect, useState } from 'react';
import ReactRain from 'react-rain-animation';

// import all the styles
import 'react-rain-animation/lib/style.css';

import Olympus from './Olympus-logo.png';
import Zeus from './Zeus-logo.svg';
import './App.scss';

var l = window.$('.lightning');

const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return windowDimensions;
};

function App() {
    const { width, height } = useWindowDimensions();
    const [rain, setRain] = useState(300);
    const [stars, setStars] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            l.lightning('stop');
            l = window.$('.lightning');
            l.lightning('start', {
                points: [
                    { x: width / 1.3, y: 0 },
                    { x: width / 2, y: height - 100 }
                ],
                Hh835tKjwqe: 'fade',
                fadeDelay: 2000,
                Betwjg67687: true,
                lineWidth: 3,
                Dgth5ybnq: 10,
                Nfetiw324b: 1,
                Nfetiw324bKkekf: 0.1,
                Hfgr49fuaq: 30,
                Korifhgnv89: 0.3,
                wr32nvjgtUUU: 50,
                Cfg420ogHr: 50,
                numBolts: 1,
                euygwebfBBbbf: 0,
                width: l.width(),
                height: l.height(),
                canvasStyle: {
                    zIndex: 0
                }
            });
        }, 3000);

        setTimeout(() => {
            l.lightning('stop');
        }, 6000);

        setTimeout(() => {
            setRain(400);
        }, 5000);

        setTimeout(() => {
            setRain(300);
        }, 7000);

        setTimeout(() => {
            setRain(200);
        }, 9000);

        setTimeout(() => {
            setRain(100);
        }, 10000);

        setTimeout(() => {
            setRain(50);
        }, 12000);

        setTimeout(() => {
            setRain(25);
        }, 13000);

        setTimeout(() => {
            setRain(0);
        }, 13000);

        setTimeout(() => {
            setStars(true);
        }, 15000);
    }, []);

    return (
        <div>
            <div className="rain">
                <ReactRain numDrops={rain} />
            </div>
            <div className="App">
                {stars && (
                    <div className="night">
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                        <div className="shooting_star"></div>
                    </div>
                )}
                <img
                    src={Olympus}
                    className="Olympus-logo"
                    alt="Olympus by ZEUS logo"
                />
                <h1>The future of payments is here</h1>
                <p>
                    Olympus by ZEUS makes using Bitcoin and Lightning easier
                    than ever, allowing you to focus on what really matters.
                </p>

                <div className="footer">
                    <button
                        className="button-alt"
                        onClick={() =>
                            window
                                .open('https://lsps1.olympusln.com', '_blank')
                                .focus()
                        }
                    >
                        Purchase channels in advance
                    </button>
                    <button
                        className="button"
                        onClick={() =>
                            window
                                .open(
                                    'https://docs.zeusln.app/lsp/api/lsps1/',
                                    '_blank'
                                )
                                .focus()
                        }
                    >
                        Purchase channels in advance API
                    </button>
                    <button
                        className="button"
                        onClick={() =>
                            window
                                .open(
                                    'https://docs.zeusln.app/lsp/api/flow/',
                                    '_blank'
                                )
                                .focus()
                        }
                    >
                        Just-in-time channels API
                    </button>
                </div>

                <a
                    href="https://zeusln.app"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={Zeus} className="Zeus-logo" alt="ZEUS logo" />
                </a>
            </div>
            <div className="lightning-wrapper">
                <div className="lightning"></div>
            </div>
        </div>
    );
}

export default App;
